import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import {
  getError,
  getLoading,
  getOfferWeb,
  getRatesParams,
} from 'base/store/selectors';

import { useLookupRequestFromPath } from 'base/utils/useParamsFromPath';
import { useRatesRequest } from 'base/utils/API/requests/useRatesRequest';
import { ScreenError } from 'components/sections/Screen/Error';
import { ScreenLoading } from 'components/sections/Screen/Loading';

export default function FirmOffers({ location, pageContext }) {
  const [isGeneralError, setIsGeneralError] = useState();
  const ratesRequest = useRatesRequest();
  const { firstName, areParamsLoaded } = useLookupRequestFromPath(location);
  const ratesParams = useSelector(getRatesParams);
  const error = useSelector(getError);
  const loading = useSelector(getLoading);
  const offerWeb = useSelector(getOfferWeb);

  useEffect(() => {
    if (location.hash === '') {
      setIsGeneralError(true);
    }
  }, [location]);

  const isNoRates = ratesParams.ApiMessage?.toLowerCase().includes('no rates');

  useEffect(() => {
    if (areParamsLoaded) {
      if (offerWeb && !loading.isLoading) {
        const pathName = pageContext?.offerWebToPathNameMap?.[offerWeb];
        if (pathName) {
          navigate(`/${pathName}`);

          if (!isNoRates) {
            // Remove it for clients who don't need offers
            ratesRequest.submitRequest();
          }
        } else {
          setIsGeneralError(true);
        }
      }
    }
  }, [error, offerWeb, loading, areParamsLoaded]);

  if (isGeneralError) {
    return <ScreenError />;
  }

  return <ScreenLoading firstName={firstName} />;
}

FirmOffers.propTypes = {
  location: shape(),
  pageContext: shape(),
};
