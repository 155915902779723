import React from 'react';

import styles from './styles.module.css';

import { Link } from 'components/elements/Links/Link';

export const ScreenError = () => (
  <section className="section">
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>Sorry, we are not getting a match</h1>
        <h3>on Your PreApproval Number + Zip Code entered.</h3>
        <p>
          Please enter the PreApproval Number and Zip Code that was printed on
          Your PreApproval letter mailed to you.
        </p>
        <Link to="/" variant="lg">
          Back to the home page
        </Link>
      </div>
    </div>
  </section>
);
