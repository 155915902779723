import React from 'react';
import PropTypes from 'prop-types';

import { BlobSVG, LotsOfDotsPNG, HourglassSVG } from 'base/assets/decorations';
import { Spinner } from 'components/elements/Spinner';

import styles from './styles.module.css';

export const ScreenLoading = ({ firstName }) => (
  <section className="section">
    <div className={styles.wrapper}>
      <img
        className={styles.blob}
        src={BlobSVG}
        alt="blob"
        loading="lazy"
        decoding="async"
      />
      <img
        className={styles.dots}
        src={LotsOfDotsPNG}
        alt="dots"
        loading="lazy"
        decoding="async"
      />

      <div className={styles.content}>
        <img
          className={styles.hourglass}
          src={HourglassSVG}
          alt="hourglass"
          loading="lazy"
          decoding="async"
        />
        <h1>
          Congrats {firstName}!
          <br />
          You Are PreApproved
        </h1>
        <p>Data is loading so please wait a bit!</p>
        <Spinner theme="secondary" />
      </div>
    </div>
  </section>
);

ScreenLoading.propTypes = {
  firstName: PropTypes.string,
};
